$black: #000000;
$white: #ffffff;
$gray-dark: #282a2b;
$gray-medium: #797979;
$gray-light: #c5c3c3;
$blue: #3A2AAF;
$purple: #E125B5;
$red: #EE435A;
$green: #20c997;
$nav-hover-color: #81e9f6;

$primary-color: #304d82;
$highlighted: #fcc6cd40;

$theme-background: #304d82;
$content-mobile-top-padding: 74px;
$content-desktop-top-padding: 98px;

$nav-logo-desktop-wdith: 290px;
$nav-logo-mobile-wdith: 240px;

$nav-menu-icon-paddding: 8px;
$heading-font-weight: 800;

$desktop-heading-font-size: 28px;
$mobile-heading-font-size: 28px;

$small-text-font-size: 14px;

$mobile-heading-align: center;
$desktop-heading-align: left;

$dark-button-bg: $black;
$dark-button-color: $white;
$dark-button-radius: 40px ;
$dark-button-font-weight: 500 ;
$dark-button-font-size: 18px ;

$blog-card-radius: 40px;
$blog-list-margin: 10px  0;


$button-radius: 40px ;
$button-font-weight: 600 ;
$button-font-size: 18px ;
$button-border: none ;
$button-padding: 10px 30px ;
$button-hover-padding: 10px 50px ;

$credit-builder-plus-blue-dark: #2f6cb3;
$credit-builder-plus-blue-light: #51c4f1;

$plan-border-radius: 30px;

$nav-link-font-size: 16px;

$navbar-nav-link-padding-x: 1.5rem;
$navbar-toggler-padding-y: 1.25rem;

$section-padding: 50px 0;

$heading-font-size: 42px;

$regular-font-size: 18px;
$regular-font-alignment-mobile: center;
$regular-font-alignment-desktop: left;

$button-alignment-mobile: center;
$button-alignment-desktop: left;



$hero-section-img-desktop-width: 350px;
$hero-section-img-mobile-width: 100%;

$mobile-section-min-height-mobile: 440px;
$mobile-section-min-height-desktop: 250px;

$blog-img-width: 100%;
$blog-img-height: 160px;
$blog-inner-content-height: 520px;


$footer-nav-item-color: $gray-medium;

$whatsapp-icon-width: 80px;
$whatsapp-icon-position: fixed;
$whatsapp-icon-mobile-position-right: 10px;
$whatsapp-icon-mobile-position-bottom: 20px;

$whatsapp-icon-desktop-position-right: 20px;
$whatsapp-icon-desktop-position-bottom: 30px;

