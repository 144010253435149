
@import 'variables';

body{
    font-family: 'Poppins', Helvetica, sans-serif;
}
a:hover{
    color: #304d82;
    text-decoration: none;
}
p{
    font-size: $regular-font-size;
}
.bg-theme{
    background: $theme-background !important;
}
.button-container{
    text-align: $button-alignment-mobile;
}
.btn{
    cursor: pointer;
    border-radius: $button-radius !important;
    font-weight:  $button-font-weight !important;
    font-size:  $button-font-size !important;
    border:  $button-border;
    padding: $button-padding;
    transition: 500ms;
 }
 
.form-control{
    font-size: 16px !important;
    
}
.heading-text{
    font-size: $mobile-heading-font-size;
    text-align: $mobile-heading-align;
}
.small-text{
    font-size: $small-text-font-size;
}
.bg-highlight{
    background-color: $highlighted;
}
.text-gray{
    color: $gray-medium;
}
.text-ligh-gray{
    color: $gray-light;
}
.text-green{
    color: $green;
}
.regular-text{
    text-align: $regular-font-alignment-mobile;
    font-size: $regular-font-size;
}
.text-decor-none{
    text-decoration: none;
}


header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background: $theme-background !important;
    background-size: 100% 100%;
}
// .navbar:before{
//     content: none;
// }
// .navbar .container:before{
//     content: none;
// }
.brand-logo img,.mobile-icon .logo img{
    width: $nav-logo-mobile-wdith;
}

header .navbar .nav-link{
    color: $white !important;
    font-size: $nav-link-font-size !important;
}
header .navbar .nav-item{
    padding-left: 20px;
}
.navbar-toggler{
    padding: $nav-menu-icon-paddding;
}




.content{
    width: 100%;
    padding-top: $content-desktop-top-padding !important;
    overflow: hidden;
}
hr{
    
    background-color: $theme-background;
    height: 6px;
    width: 100%;
    opacity: 1;
    border: navajowhite;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}   
.hero-section{
    
    background: linear-gradient(153deg, #304d82 59%, #4e78c7 88%);
    color: #FFF;
}

.hero-section .mobile_img img{
    width: $hero-section-img-mobile-width;
}
.hero-section div{
    transition: 500ms;
}

.blog .block .inner-content{
    height: $blog-inner-content-height;
}

.blog img{
    width: $blog-img-width;
}
.blog .block{
    transition: 500ms;
}
.blog .block:hover {
    background-color: $gray-light;
    
    transition: 500ms;
}
.sim_plan .block{
    transition: 500ms;
    border-radius: $blog-card-radius;
    
}
.shadow-line{
    margin-bottom: 20px !important;
    height: 2px;
    background: #FFF;
    width: 150px;
    -moz-box-shadow: 0 5px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 5px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.4);
}
.sim_plan .plan{
    border-radius: $plan-border-radius;
}
.sim_plan h2{
    background: rgb(51,22,165);
  background: $theme-background;
  background: -webkit-linear-gradient($theme-background);
  background: -moz-linear-gradient($theme-background);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sim_plan .old-price{
    text-decoration: line-through !important;
    position: absolute;
    left: 100px;
}
.sim_plan .plan .original-price{
    margin-top: 20px;
}
.sim_plan .plan .input-box .label{
    position: absolute;
    top: 16px;
    left: 10px;
}
.sim_plan .plan .input-box input{
    padding-left: 140px !important;
}

.mobile_section{
    background: $red;
    height: $mobile-section-min-height-mobile;
    position: relative;
    background: linear-gradient(153deg, #304d82 59%, #4e78c7 88%);
    color: #FFF;
    background-size: 100% 100%;
}

.mobile_section img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    
}

footer .nav-link{
    text-align: center;
    font-size: $nav-link-font-size !important;
}
.whatsapp-icon img{
    cursor: pointer;
    position: $whatsapp-icon-position;
    bottom: $whatsapp-icon-mobile-position-bottom;
    right: $whatsapp-icon-mobile-position-right;
    width: $whatsapp-icon-width;
    
}
.nav-menu {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.nav-menu li .top-secret-img{
    cursor: pointer;
}
.nav-menu li{
    transition: 0.7s;
    list-style: none;
}

.nav-menu li a{
    padding: 15px;
}
.nav-menu li a:hover,.nav-menu li a:active{
    
    font-size: medium;
    color: $nav-hover-color;
    background-color: #203c71;
}
.nav-menu li a:focus{
    text-decoration: none;
}
.nav-menu .mobile-icon{
    display: none !important;
}
header .navbar-toggler{
    display: none;
}
footer .nav-menu li a:hover,footer .nav-menu li a:active{
    color: $white;
    background-color:$gray-dark;
}
.credit-builder-section p{
    margin: 20px 0;
}
.credit-builder-section img{
    width: 550px;
    float: right;
}
@media (max-width: 767px){
    .credit-builder-section img{
        width: 100%;
    }
    .content{
        padding-top: $content-mobile-top-padding !important;
    }
    header .navbar-toggler{
        display: block;
    }
    header .nav-menu .mobile-icon{
        padding: 1.5rem;
        width: 100%;
        display: flex !important;
        border-bottom: solid #fff;
    }
    header .nav-menu li{
        text-align: left;
    }
    .nav-menu{
        position: absolute !important;
        height: 100vh;
        width: 0;
        z-index: 999;
        top: 0;
        padding: 0 !important;
        left: 0px;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        align-items: flex-start;
        color: #304d82 !important;
        background-color: #ffffff;
        font-weight: 600;
        flex-direction: column;
    }
    header .active{
        width: 100% !important;
    }
    h2{
        font-size: 30px;
    }
    .mobile_view{
        width: 100%;
    }
}
.text-32{
    font-size: 30px;
    line-height: 1.5;
}
// Desktop CSS
@media (min-width: 768px){
    .whatsapp-icon img{
        bottom: $whatsapp-icon-desktop-position-bottom;
    right: $whatsapp-icon-desktop-position-right;
    }
    .sim_plan .old-price{
        left: 70px;
    }
    .navbar-brand img{
        width: $nav-logo-desktop-wdith;
    }
    .heading-text{
        font-size: $desktop-heading-font-size;
        text-align: $desktop-heading-align;
    }
    .regular-text{
        text-align: $regular-font-alignment-desktop;
        
    }
    .button-container{
        text-align: $button-alignment-desktop;
    }
    .hero-section img{
        width: $hero-section-img-desktop-width;
    }
    .navbar-expand-lg .navbar-collapse {
        justify-content: end !important;
    }
    .mobile_section{
        height: $mobile-section-min-height-desktop;    
        display: flex;
        align-items: center;
    }
    
    
    
    .mobile_section img{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 460px;
        
    }
    footer .nav-menu{
        display: flex !important;
        flex-direction: row;
        justify-content: space-evenly;
    }
    footer a{
        text-decoration: none;
    }
}
@media (min-width: 992px){
    .hero-section img{
        width: 260px !important;
    }
    .mobile_section{
        height: 230px;   
    } 
    .mobile_section .text-content{
        display: block;
    }
    .mobile_section img{
        width: 475px;
    }
}
@media (min-width: 1200px){
    .hero-section img{
        width: 260px !important;
    }
    .sim_plan .old-price{
        left: 56px;
    }
    .mobile_section{
        height: 240px;   
    } 
}

section{
    padding: $section-padding !important;
}